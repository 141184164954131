import React, { useState, useEffect } from 'react';
import { CreateJoinProps, PublicRoom } from "@/src/Types";

export const CreateJoin: React.FC<CreateJoinProps> = ({ createRoom, joinRoom }) => {
    const [roomId, setRoomId] = useState('');
    const [playerName, setPlayerName] = useState('');
    const [isPublic, setIsPublic] = useState(true);
    const [language, setLanguage] = useState('en');
    const [publicRooms, setPublicRooms] = useState<PublicRoom[]>([]);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchPublicRooms();
    }, []);

    const fetchPublicRooms = async () => {
        try {
            const response = await fetch('/api/public-rooms');
            const data = await response.json();
            setPublicRooms(data);
        } catch (error) {
            console.error('Error fetching public rooms:', error);
        }
    };

    const handleCreateRoom = () => {
        if (playerName.trim() === '') {
            setError('Please enter your name before creating a room.');
            return;
        }
        setError('');
        createRoom(playerName, isPublic, language);
    };

    const handleJoinRoom = () => {
        if (playerName.trim() === '') {
            setError('Please enter your name before joining a room.');
            return;
        }
        if (roomId.trim() === '') {
            setError('Please enter a room ID to join.');
            return;
        }
        setError('');
        joinRoom(roomId, playerName);
    };

    const handleJoinPublicRoom = (roomId: string, playerName: string) => {
        if (playerName.trim() === '') {
            setError('Please enter your name before joining a room.');
            return;
        }
        setError('');
        joinRoom(roomId, playerName);
    }

    return (
        <div className="min-h-screen bg-gray-100 text-gray-800">
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
                {error && (
                    <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
                        {error}
                    </div>
                )}
                <div className="grid md:grid-cols-2 gap-12">
                    <div className="bg-white rounded-xl shadow-md p-8 space-y-6">
                        <h2 className="text-2xl font-bold text-teal-700">Create a Room</h2>
                        <div className="space-y-4">
                            <input
                                type="text"
                                value={playerName}
                                onChange={(e) => setPlayerName(e.target.value)}
                                placeholder="Your Name (required)"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 text-gray-700 placeholder-gray-400"
                                required
                            />
                            <select
                                value={language}
                                onChange={(e) => setLanguage(e.target.value)}
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 text-gray-700"
                            >
                                <option value="al">Albanian</option>
                                <option value="en">English</option>
                                <option value="es">Spanish</option>
                                <option value="fr">French</option>
                            </select>
                            <label className="flex items-center space-x-3 text-gray-600">
                                <input
                                    type="checkbox"
                                    checked={isPublic}
                                    onChange={(e) => setIsPublic(e.target.checked)}
                                    className="form-checkbox h-5 w-5 text-teal-600 rounded focus:ring-teal-500"
                                />
                                <span>Public Room</span>
                            </label>
                            <button
                                onClick={handleCreateRoom}
                                className="w-full bg-teal-600 hover:bg-teal-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out"
                            >
                                Create Room
                            </button>
                        </div>
                    </div>
                    <div className="bg-white rounded-xl shadow-md p-8 space-y-6">
                        <h2 className="text-2xl font-bold text-teal-700">Join a Room</h2>
                        <div className="space-y-4">
                            <input
                                type="text"
                                value={playerName}
                                onChange={(e) => setPlayerName(e.target.value)}
                                placeholder="Your Name (required)"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 text-gray-700 placeholder-gray-400"
                                required
                            />
                            <input
                                type="text"
                                value={roomId}
                                onChange={(e) => setRoomId(e.target.value)}
                                placeholder="Room ID"
                                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 text-gray-700 placeholder-gray-400"
                            />
                            <button
                                onClick={handleJoinRoom}
                                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out"
                            >
                                Join Room
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mt-12 bg-white rounded-xl shadow-md p-8">
                    <h2 className="text-2xl font-bold text-teal-700 mb-6">Public Rooms</h2>
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                            <tr className="text-left text-gray-600 border-b border-gray-200">
                                <th className="pb-3 font-semibold w-1/4">Room ID</th>
                                <th className="pb-3 font-semibold w-1/4">Players</th>
                                <th className="pb-3 font-semibold w-1/4">Language</th>
                                <th className="pb-3 font-semibold w-1/4">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {publicRooms.map((room) => (
                                <tr key={room.id} className="border-b border-gray-200">
                                    <td className="py-3 w-1/4">{room.id}</td>
                                    <td className="py-3 w-1/4">{room.players}/10</td>
                                    <td className="py-3 w-1/4">{room.language}</td>
                                    <td className="py-3 w-1/4">
                                        <button
                                            onClick={() => handleJoinPublicRoom(room.id, playerName)}
                                            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
                                        >
                                            Join
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </main>
        </div>
    );
};