import React from 'react';
import { Users, MessageCircle, HelpCircle } from 'lucide-react';

interface MainLayoutProps {
    children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 text-white">
        <header className="bg-white/10 backdrop-blur-md shadow-lg">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <h1 className="text-2xl sm:text-3xl font-bold text-white">Word Imposter</h1>
                        <img
                            src="/logo512.png"
                            alt="Word Imposter Logo"
                            className="h-8 w-8 mr-2"
                        />
                    </div>
                </div>
            </div>
        </header>
            <div className="bg-white/10 backdrop-blur-md rounded-lg shadow-xl p-6">
                {children}
            </div>
        <footer className="bg-white/10 backdrop-blur-md mt-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 text-center">
                <p>&copy; 2024 Word Imposter. All rights reserved.</p>
            </div>
        </footer>
    </div>
);

export default MainLayout;