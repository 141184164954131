import React from 'react';
import {GameLobbyProps, Player} from '../Types';

export const GameLobby: React.FC<GameLobbyProps> = ({
                                                        roomId,
                                                        players,
                                                        waitingPlayers,
                                                        isCreator,
                                                        language,
                                                        startGame,
                                                    }) => {
    return (
        <div className="bg-white/10 p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Game Lobby</h2>
            <p className="mb-2">Room ID: {roomId}</p>
            <p className="mb-4">Language: {language}</p>
            <div className="grid md:grid-cols-2 gap-4">
                <div>
                    <h3 className="text-xl font-semibold mb-2">Active Players:</h3>
                    <ul className="list-disc pl-5">
                        {players.map((player) => (
                            <li key={player.id}>
                                {player.name} {player.isCreator ? '(Creator)' : ''}
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3 className="text-xl font-semibold mb-2">Waiting Players:</h3>
                    <ul className="list-disc pl-5">
                        {waitingPlayers.map((player) => (
                            <li key={player.id}>{player.name}</li>
                        ))}
                    </ul>
                </div>
            </div>
            {isCreator && (
                <button
                    onClick={startGame}
                    className="mt-6 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                >
                    Start Game
                </button>
            )}
        </div>
    );
};